import React, { useState, useEffect } from 'react';  
import './ConflictModal.css';
import closeIcon from './../../assets/close-icon.png';
import infoIcon from './../../assets/orange-info-icon.png';

const ConflictModal = ({
  show,
  onClose,
  conflictValues,
  reportEndDates,
  onApplyChange,
  isRehireConflict
}) => {
  const [selectedConflict, setSelectedConflict] = useState(null);
  const [manualDate, setManualDate] = useState('');
  const [showError, setShowError] = useState(false);

  const fieldDisplayNames = {
    GrossCompensation: 'Gross Compensation',
    FullName: 'Full Name',
    FirstName: 'First Name',
    LastName: 'Last Name',
    MiddleName: 'Middle Name',
    OwnershipPercent: 'Ownership Percent',
    SSN: 'SSN',
    DateOfBirth: 'Date of Birth',
    DateOfHire: 'Date of Hire',
    DateOfReHire: 'Date of Rehire',
    DateOfTermination: 'Date of Termination',
    Status: 'Status',
    GrossCompensationNote: 'Gross Compensation Note',
    HoursWorked: 'Hours Worked',
    HoursWorkedNote: 'Hours Worked Note',
    DeferALS: 'Defer ALS',
    ParticipantRoth: 'Participant Roth',
    CompanyMatch: 'Company Match',
    CompanyProfitSharing: 'Company Profit Sharing',
    AnnualLoanPayment: 'Annual Loan Payment',
    RollOver: 'Rollover',
  };

  useEffect(() => {
    if (show) {
      setManualDate('');
      setSelectedConflict(null);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const getFieldDisplayName = (field) => {
    return fieldDisplayNames[field] || field;
  };

  const formatDateWithSlashes = (dateString) => {
    if (!dateString) return '';
    const [month, day, year] = dateString.split('-');
    return `${month}/${day}/${year}`;
  };

  const getPriorYearTerminationDate = (employeeData) => {
    const terminationValues = employeeData?.DateOfTermination?.Values || [];
    const priorYearTermination = terminationValues.find(v => v.Source === 'PriorYear');
    
    if (priorYearTermination?.Value) {
      return formatDateWithSlashes(priorYearTermination.Value);
    }
    const terminationDates = terminationValues
      .filter(v => v.Value)
      .map(v => ({
        ...v,
        dateObj: new Date(v.Value.split('-').join('/')) 
      }))
      .sort((a, b) => b.dateObj - a.dateObj);
      
    return terminationDates.length > 0 
      ? formatDateWithSlashes(terminationDates[0].Value) 
      : '';
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };
  
  const isDateAfterTermination = (inputDate, employeeData) => {
    // Get the same termination date we're displaying to the user
    const terminationDate = getPriorYearTerminationDate(employeeData);
    if (!terminationDate || !inputDate) return false;
  
    // Create Date objects
    const terminationDateTime = new Date(terminationDate);
    const rehireDateTime = new Date(inputDate);
    
    // Add basic validation to ensure both dates are valid
    if (isNaN(terminationDateTime) || isNaN(rehireDateTime)) return false;
  
    // Compare the dates
    return rehireDateTime > terminationDateTime;
  };

  const handleApplyChange = () => {
    if (isRehireConflict) {
      const parsedDate = parseDate(manualDate);
      const isValidDate = parsedDate && isDateAfterTermination(parsedDate, conflictValues.employeeData);
      
      if (!parsedDate || !isValidDate) {
        setShowError(true);
        return;
      }
      // Handle rehire date submission
      const { employeeKey, field, isOldData } = conflictValues.conflicts[0];
      const formattedDate = formatDate(manualDate);
      onApplyChange({
        Value: formattedDate,
        Source: 'manual_input',
        employeeKey,
        field,
        isOldData,
        isRehireConflict
      });
    } else if (selectedConflict) {
      // Handle regular conflict selection
      onApplyChange({...selectedConflict, isRehireConflict});
    }
    onClose();
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${month}-${day}-${year}`;
  };

  const getValueWithFallback = (fieldData) => {
    // If FinalValue isn't 'conflict', use it
    if (fieldData.FinalValue.Value !== 'conflict') {
      return fieldData.FinalValue.Value;
    }
  
    // If it is 'conflict', check Values array
    const values = fieldData.Values || [];
    
    // Look for PriorYear first
    const priorYearValue = values.find(v => 
      v.Source.toLowerCase() === 'prioryear'
    )?.Value;
    if (priorYearValue) return priorYearValue;
  
    // Then look for manual_input
    const manualValue = values.find(v => 
      v.Source.toLowerCase() === 'manual_input'
    )?.Value;
    if (manualValue) return manualValue;
  
    // Finally, take first value if it exists
    return values[0]?.Value || '';
  };

  if (isRehireConflict) {
    const firstName = getValueWithFallback(conflictValues.employeeData.FirstName);
    const lastName = getValueWithFallback(conflictValues.employeeData.LastName);
    const fullName = `${firstName} ${lastName}`.trim();
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Missing Rehire Date</h2>
            <img
              src={closeIcon}
              alt="Close"
              className="modal-close-icon"
              onClick={onClose}
            />
          </div>
  
          <hr className="modal-header-divider" />
  
          <div className="rehire-banner">
            <img src={infoIcon} alt="Info" className="info-icon" />
            <span>
              Our records show this employee was terminated on {getPriorYearTerminationDate(conflictValues.employeeData)}.<br />
              Please provide a rehire date after that date.
            </span>
          </div>
  
          <table className="modal-table">
            <thead>
              <tr>
                <th>EMPLOYEE</th>
                <th>REHIRE DATE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{fullName}</td>
                <td>
                  <input
                    type="date"
                    value={manualDate}
                    onChange={(e) => {
                      setManualDate(e.target.value);
                      setShowError(false);
                    }}
                    className={showError ? 'conflict-modal-date-input-error' : ''}
                  />
                </td>
              </tr>
            </tbody>
          </table>
  
          <div className="modal-button-container">
            <button 
              className="modal-apply-button" 
              onClick={handleApplyChange}
            >
              SUBMIT DATE
            </button>
          </div>
  
          {showError && (
            <div className="conflict-modal-error-message">
              Please enter a Rehire Date after {getPriorYearTerminationDate(conflictValues.employeeData)}.
            </div>
          )}
        </div>
      </div>
    );
  }

  // Regular conflict modal code remains the same
  const isFullNameConflict = conflictValues.conflicts[0]?.field === 'FullName';

  const getUniqueFullNames = (values) => {
    if (!isFullNameConflict) return values;

    const uniqueNames = {};
    values.forEach(conflict => {
      const fullName = `${conflict.firstName?.Value || ''} ${conflict.lastName?.Value || ''}`.trim();
      if (!uniqueNames[fullName]) {
        uniqueNames[fullName] = conflict;
      }
    });
    return Object.values(uniqueNames);
  };

  const currentValues = getUniqueFullNames(
    conflictValues.conflicts.filter((conflict) => conflict.Source !== 'PriorYear')
  );

  const priorYearValues = conflictValues.conflicts.filter(
    (conflict) => conflict.Source === 'PriorYear'
  );

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>
            Choose the Correct {getFieldDisplayName(conflictValues.conflicts[0].field)}
          </h2>
          <img
            src={closeIcon}
            alt="Close"
            className="modal-close-icon"
            onClick={onClose}
          />
        </div>

        <hr className="modal-header-divider" />

        <p className="discrepancy-text">
          We found discrepancies in the values of the documents you uploaded.
        </p>

        <table className="modal-table">
          <thead>
            <tr>
              <th>{getFieldDisplayName(conflictValues.conflicts[0].field).toUpperCase()}</th>
              <th>SOURCE</th>
            </tr>
          </thead>
          <tbody>
            {currentValues.map((conflict, index) => {
              if (isFullNameConflict) {
                const fullName = `${conflict.firstName?.Value || ''} ${conflict.lastName?.Value || ''}`.trim();
                const firstNameSource = conflict.firstName?.Source || '';
                const lastNameSource = conflict.lastName?.Source || '';
                const source = firstNameSource === lastNameSource ? firstNameSource : `${firstNameSource} / ${lastNameSource}`;

                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="conflictSelection"
                        checked={selectedConflict === conflict}
                        onChange={() => setSelectedConflict(conflict)}
                      />
                      {fullName || 'No Name Provided'}
                    </td>
                    <td>{source || 'Multiple Sources'}</td>
                  </tr>
                );
              }
              return (
                <tr key={index}>
                  <td>
                    <input
                      type="radio"
                      name="conflictSelection"
                      checked={selectedConflict === conflict}
                      onChange={() => setSelectedConflict(conflict)}
                    />
                    {conflict.Value}
                  </td>
                  <td>{conflict.Source}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {priorYearValues.length > 0 && (
          <>
            <div className="modal-header previous-census">
              <h2>Previous Census Data</h2>
            </div>
            <table className="modal-table">
              <thead>
                <tr>
                  <th>{getFieldDisplayName(conflictValues.conflicts[0].field).toUpperCase()}</th>
                  <th>SOURCE</th>
                </tr>
              </thead>
              <tbody>
                {priorYearValues.map((conflict, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="conflictSelection"
                        checked={selectedConflict === conflict}
                        onChange={() => setSelectedConflict(conflict)}
                      />
                      {conflict.Value}
                    </td>
                    <td>{conflict.Source}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        <div className="modal-button-container">
          <button 
            className="modal-apply-button" 
            onClick={handleApplyChange}
            disabled={!selectedConflict}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConflictModal;