const CONFIG = {
    local: {
      hostname: ['localhost', '127.0.0.1'],
      identityServer: 'https://identity-dev.guidantfinancial.com',
      clientId: 'censusupload-local',
      redirectUri: 'https://localhost:3000/signin-oidc',
      logoutRedirectUri: 'https://localhost:3000/'
    },
    dev: {
      hostname: ['censusupload-dev.guidantfinancial.com', 'guidant-census-data.azurewebsites.net'],
      identityServer: 'https://identity-dev.guidantfinancial.com',
      clientId: 'censusupload-dev',
      redirectUri: 'https://censusupload-dev.guidantfinancial.com/signin-oidc',
      logoutRedirectUri: 'https://censusupload-dev.guidantfinancial.com/'
    },
    staging: {
      hostname: ['censusupload-stg.guidantfinancial.com', 'guidant-census-data-stg.azurewebsites.net'],
      identityServer: 'https://identity-stg.guidantfinancial.com',
      clientId: 'censusupload-stg',
      redirectUri: 'https://censusupload-stg.guidantfinancial.com/signin-oidc',
      logoutRedirectUri: 'https://censusupload-stg.guidantfinancial.com/'
    },
    prod: {
      hostname: ['censusupload.guidantfinancial.com', 'guidant-census-data-prod.azurewebsites.net'],
      identityServer: 'https://identity.guidantfinancial.com',
      clientId: 'censusupload',
      redirectUri: 'https://censusupload.guidantfinancial.com/signin-oidc',
      logoutRedirectUri: 'https://censusupload.guidantfinancial.com/'
    }
  };
  
  export const getConfig = () => {
    const currentHostname = window.location.hostname;
    const environment = Object.values(CONFIG).find(env => 
      env.hostname.includes(currentHostname)
    );
  
    if (!environment) {
      throw new Error('Unknown environment: Unable to determine configuration');
    }
  
    return environment;
  };